
















































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Contact extends Vue {
  private mailAddress = "tombrooksengineering@gmail.com";

  private copyMailToClipboard() {
    const copyText = document.getElementById("email");
    if (copyText == null) return;
    let spanText = copyText.textContent;
    if (spanText == null) return;
    spanText = spanText.trim();

    const dummyTempTextArea = document.createElement("textarea");
    dummyTempTextArea.value = spanText;
    document.body.appendChild(dummyTempTextArea);
    dummyTempTextArea.select();

    /* Copy the text inside the text field */
    document.execCommand("copy");
    dummyTempTextArea.remove();
  }
}
